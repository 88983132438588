import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Hero from '@components/Hero';
import AboutUs from '@components/AboutUs';
import Team from '@components/Team';
import Services from '@components/Services';
import Contact from '@components/Contact';
import Slideshow from '@components/Slideshow';
import Booking from '@components/Booking';
import Link from '@core/Link';

const HomePage = ({
  data: {
    contentfulIndexPage: { image, text },
  },
}) => (
  <Main>
    <Hero title={text} image={image} isHomepage />

    <Contact isHomepage />
    <Booking number="004994151101" text="0941 511 01" />
  </Main>
);

const Main = styled.main``;

export const homepageQuery = graphql`
  query HomepageQuery {
    contentfulIndexPage {
      text
      image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          quality: 75
        )
      }
    }
  }
`;

export default HomePage;
